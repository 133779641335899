import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Select from 'react-select';
import HappyKid from '../img/sa-enkelt-bg.jpg';
import { getAssociationsQuery, getTeamsSafeQuery, getTeamMembersSafeQuery, getZonesPublicQuery } from '../queries/queries';
import { useLazyQuery, useQuery } from 'react-apollo';
import { useAutosize } from '../common';
import { useNavigationContext } from '../context/NavigationContext';
import WebshopNavigation from './webshop/WebshopNavigation';
import { REACT_APP_BRAND } from '../env';
import StartPageMobiles from '../img/startpage-mobiles.png';

function Startpage() {

  const history = useHistory();
  const { setNavigationActivated } = useNavigationContext();

  const { data: associationsData } = useQuery(getAssociationsQuery);
  const [getTeams, { data: teamsData }] = useLazyQuery(getTeamsSafeQuery);
  const [getTeamMembers, { data: teamMembersData }] = useLazyQuery(getTeamMembersSafeQuery);
  const { data: zonesData } = useQuery(getZonesPublicQuery);
  const [filteredAssociations, setFilteredAssociations] = useState([]);
  const [selectedAssociation, setSelectedAssociation] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState();

  const { height } = useAutosize();

  useEffect(() => {
    setNavigationActivated(false);

    // Set background color, fix for mobile styling issue
    document.querySelector('.App').style.backgroundColor = '#82C8C8';

    return () => {
      document.querySelector('.App').style.backgroundColor = '#EEF8F6';
    }
  }, []);

  useEffect(() => {
    const domain = window.location.hostname;
    const visibleZones = zonesData?.zones?.filter(zone => {
      if (zone?.onlyVisibleOnDomains) {
        return zone?.onlyVisibleOnDomains?.includes(domain);
      }
      return true;
    });

    if (associationsData && visibleZones) {
      const filteredAssociations = associationsData?.associations?.filter(association =>
        // If association is in visible zone
        visibleZones?.some(zone => zone._id === association.zoneId) &&
        // If association is not hidden
        association?.showInShopList !== false
      );
      setFilteredAssociations(filteredAssociations);
    }

  }, [associationsData, zonesData]);


  useEffect(() => {
    // Förening
    if (selectedTeam?.value === '0') {
      history.push(`/${selectedAssociation?.slug}`);
    }
    // Lag
    else if (selectedTeamMember?.value === '0') {
      history.push({
        pathname: `/${selectedAssociation?.slug}/${selectedTeam?.slug}`,
        state: { isTeamSelected: true }
      });
    }
    // Lagmedlem
    else if (selectedAssociation && selectedTeam && selectedTeamMember) {
      history.push(`/${selectedAssociation?.slug}/${selectedTeam?.slug}/${selectedTeamMember?.slug}`);
    }
  }, [
    selectedAssociation,
    selectedTeam,
    selectedTeamMember
  ]);

  // If no teams, redirect to association page
  useEffect(() => {
    if (selectedAssociation && teamsData?.teams.length === 0) {
      history.push(`/${selectedAssociation?.slug}`);
    }
  }, [teamsData]);

  // If no team members, redirect to team page
  useEffect(() => {
    if (selectedAssociation && selectedTeam && teamMembersData?.teamMembers.length === 0) {
      history.push({
        pathname: `/${selectedAssociation?.slug}/${selectedTeam?.slug}`,
        state: { isTeamSelected: true }
      });
    }
  }, [teamMembersData]);

  return (
    <div id="se-webshop" style={{ minHeight: height }} className={REACT_APP_BRAND === 'SAENKELT' ? 'saenkelt' : ''}>
      <WebshopNavigation />

      <div className="container relative" style={{ minHeight: height - 65 }}>
        {REACT_APP_BRAND === 'SAENKELT' && <img className='bg-img' src={HappyKid} alt="" style={{ minHeight: height - 65 }} />}
        <div className="content relative">
          <h1>Köp ett häfte</h1>
          {REACT_APP_BRAND === 'SAENKELT'
            ? <p>Köp ett rabatthäfte och stötta föreningslivet!</p>
            : <p>Köp ett rabatthäfte och stötta det lokala näringslivet!</p>
          }
          <div className="img-container">
            <img src={StartPageMobiles} alt="webshop" />
          </div>
          <span className="support">Jag vill stötta</span>
          <Select
            className={`react-select-container`}
            classNamePrefix='react-select'
            options={filteredAssociations.map(association => ({ value: association._id, label: association.name, slug: association.slug }))}
            placeholder={REACT_APP_BRAND === 'CITY' ? 'Välj stad' : 'Välj förening/skola'}
            noOptionsMessage={() => REACT_APP_BRAND === 'CITY' ? 'Hittade ingen stad' : 'Hittade ingen förening/skola'}
            onChange={(association) => {
              setSelectedAssociation({ ...selectedAssociation, value: association.value, label: association.label, slug: association.slug });
              getTeams({ variables: { associationId: association.value } });
            }}
            value={selectedAssociation}
          />
          {selectedAssociation &&
            <Select
              className={`react-select-container`}
              classNamePrefix='react-select'
              options={teamsData?.teams.map(team => ({ value: team._id, label: team.name, slug: team.slug }))}
              placeholder={'Välj lag/klass'}
              noOptionsMessage={() => 'Hittade inget lag/klass'}
              onChange={(team) => {
                setSelectedTeam({ ...selectedTeam, value: team.value, label: team.label, slug: team.slug });
                getTeamMembers({ variables: { teamId: team.value } });
              }}
              value={selectedTeam}
            />
          }
          {selectedTeam &&
            <Select
              className={`react-select-container`}
              classNamePrefix='react-select'
              options={[{ value: '0', label: 'Lagets shop' }].concat(teamMembersData?.teamMembers.map(teamMember => ({ value: teamMember._id, label: teamMember.firstname + ' ' + teamMember.lastname, slug: teamMember.slug })))}
              placeholder={'Välj en säljare'}
              noOptionsMessage={() => 'Hittade ingen säljare'}
              onChange={(teamMember) => setSelectedTeamMember({ ...selectedTeamMember, value: teamMember.value, label: teamMember.label, slug: teamMember.slug })}
              value={selectedTeamMember}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default Startpage;

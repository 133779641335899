import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { useMutation, useQuery } from '@apollo/react-hooks';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { getCompaniesQuery, getCompanyCategoriesQuery, getCompanyTypesQuery, updateCouponsInBookletMutation, getAdminableBookletsQuery, getNotAdminableBookletsQuery } from '../../queries/queries';
import { LoadingTable, EditButton, sortList, SortButton, TableSection, Button } from '../../common/index';
import CompanyForm from './CompaniesForm';
import Sort from '../../img/sort.svg';
import CompaniesTableBooklet from './CompaniesTableBooklet';
import CompanyTableItem from './CompanyTableItem';
import { useZoneContext } from '../../context/ZoneContext';

function CompaniesTable(props) {
  // Props
  const { companiesQuery, categoriesQuery, typesQuery, zoneId } = props;
  const { data: adminableBookletsData, refetch: refetchAdminableBooklets } = useQuery(getAdminableBookletsQuery, { variables: { zoneId } });
  const { data: notAdminableBookletsData, refetch: refetchNotAdminableBooklets } = useQuery(getNotAdminableBookletsQuery, { variables: { zoneId } });
  const [updateMutation, { error: errorUpdate }] = useMutation(updateCouponsInBookletMutation);

  const { adminableBooklets, notAdminableBooklets, setAdminableBookletsQuery, setNotAdminableBookletsQuery, setBookletsToHide, bookletsToHide } = useZoneContext();

  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState(1);
  const [checked, setChecked] = useState([]);
  const [editBookletCoupons, setEditBookletCoupons] = useState('');
  const [changedData, setChangedData] = useState(false);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };

  const history = useHistory();

  useEffect(() => {
    refetchAdminableBooklets();
    refetchNotAdminableBooklets();
  }, []);

  useEffect(() => {
    if (adminableBookletsData) {
      setAdminableBookletsQuery(adminableBookletsData.booklets);
    }
    if (notAdminableBookletsData) {
      setNotAdminableBookletsQuery(notAdminableBookletsData.booklets);
    }
  }, [adminableBookletsData, notAdminableBookletsData, setAdminableBookletsQuery, setNotAdminableBookletsQuery]);

  function populateEditableCheckboxes() {
    setChecked([]);
    if (adminableBookletsData?.booklets) {
      adminableBookletsData.booklets.forEach((bookletObj) => {
        if (bookletObj.couponIds) {
          const couponIds = bookletObj.couponIds;
          const bookletId = bookletObj._id;
          if (couponIds.length > 0) {
            couponIds.forEach((coupon) => {
              let id = `${bookletId}-${coupon}`;
              setChecked((prevChecked) => [...prevChecked, id])
            })
          }
        }
      })
    }
  }

  // Function to reset state on checkboxes when an edit has not been saved
  function resetChecked() {
    populateEditableCheckboxes();
  }

  // Effect hooks to set state on checkboxes for coupons already stored in booklets
  useEffect(() => {
    populateEditableCheckboxes();
  }, [adminableBookletsData])

  useEffect(() => {
    if (notAdminableBookletsData?.booklets) {
      notAdminableBookletsData.booklets.forEach((bookletObj) => {
        if (bookletObj.couponIds) {
          const couponIds = bookletObj.couponIds;
          const bookletId = bookletObj._id;
          if (couponIds.length > 0) {
            couponIds.forEach((coupon) => {
              let id = `${bookletId}-${coupon}`;
              setChecked((prevChecked) => [...prevChecked, id])
            })
          }
        }
      })
    }
  }, [notAdminableBookletsData])

  const unblock = history.block(() => {
    if (changedData) {
      if (window.confirm('Förändringar i häfte har inte sparats. Vill du fortsätta?')) {
        unblock();
      } else {
        return false;
      }
    }
  });

  // Function to update couponIds in booklet
  function updateCouponsInBooklet(e, bookletId, bookletTitle) {
    e.stopPropagation();

    let bookletIdString = bookletId.toString();
    let collectionOfCoupons = [];

    // Extract coupon id from checkbox id
    checked.forEach((checkedItem) => {
      if (checkedItem.includes(bookletIdString)) {
        let couponId = checkedItem.substring(25);
        collectionOfCoupons.push(couponId);
      }
    })

    // Options for mutation
    const opts = {
      variables: {
        _id: bookletId,
        title: bookletTitle,
        couponIds: collectionOfCoupons
      },
      refetchQueries: getAdminableBookletsQuery && [{ query: getAdminableBookletsQuery, variables: { zoneId: zoneId } }],
    }

    // Send mutation to API
    if (opts.variables._id) {
      updateMutation(opts)
        .then(result => {
          // Reset state for booklet button
          setEditBookletCoupons('');
          setChangedData(false);
        })
        .catch(useMutationError => { console.log('fromUpdateError', useMutationError, useMutationError && useMutationError.graphQLErrors) });
    }
  }

  return (
    <TableSection>
      <div className="table-header">
        <h2>Företag</h2>
        <EditButton size="sm add" setEditing={() => setEditing('new')}>Lägg till</EditButton>
      </div>
      <table className='company-table'>
        <thead>
          <tr className='company'>
            <td className="centered-logo">Bild</td>
            <td className='company-name'><SortButton name="name" sort={sort}>Företag<img className="sortBtn" src={Sort} alt="" /></SortButton></td>
            <td><SortButton name="categoryId" sort={sort}>Kategori<img className="sortBtn" src={Sort} alt="" /></SortButton></td>
            <td className='show-all-booklets-container'>
              {bookletsToHide.length > 0 && <Button className='secondary' onClick={() => setBookletsToHide([])}>Visa alla</Button>}
            </td>
            {
              // Headings and buttons for adminable booklets
              adminableBooklets?.map((bookletobj) => (
                <td key={bookletobj._id} className="booklet-info booklet-column">
                  <CompaniesTableBooklet
                    booklet={bookletobj}
                    editBookletCoupons={editBookletCoupons}
                    setEditBookletCoupons={setEditBookletCoupons}
                    resetChecked={resetChecked}
                    setChangedData={setChangedData}
                    changedData={changedData}
                    updateCouponsInBooklet={updateCouponsInBooklet}
                  />
                </td>
              ))
            }
            {
              // Headings for not adminable booklets
              notAdminableBooklets?.map((bookletobj) => (
                <td key={bookletobj._id} className="booklet-info booklet-column">
                  <CompaniesTableBooklet
                    notAdminable
                    booklet={bookletobj}
                    editBookletCoupons={editBookletCoupons}
                    setEditBookletCoupons={setEditBookletCoupons}
                    resetChecked={resetChecked}
                    setChangedData={setChangedData}
                    changedData={changedData}
                    updateCouponsInBooklet={updateCouponsInBooklet}
                  />
                </td>
              ))
            }
          </tr>
          <tr className="button-row">
            <td></td>
            <td>
              {
                // Error message
                errorUpdate && errorUpdate.graphQLErrors.map(({ message }, i) => (
                  <p className="error form" key={`updateError${i}`}>{message}</p>
                ))
              }
            </td>
            {
              // Headings and buttons for adminable booklets
              adminableBooklets?.map((bookletobj) => (
                <td key={bookletobj._id} className="booklet-info booklet-column" />
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            // Form for new company
            editing === 'new'
            && <CompanyForm key="new" categories={categoriesQuery} types={typesQuery} content={{ zoneId }} onComplete={() => setEditing('')} />
          }
          {
            // Table row for company
            !companiesQuery.companies ? <LoadingTable query={companiesQuery} /> : (
              sortList(companiesQuery.companies, sort).map((obj) => (
                editing === obj._id
                  // Form to edit company
                  ? <CompanyForm key={obj._id} categories={categoriesQuery} types={typesQuery} content={obj} onComplete={() => setEditing('')} />
                  : (
                    <CompanyTableItem
                      key={obj._id}
                      company={obj}
                      categoriesQuery={categoriesQuery}
                      setEditing={setEditing}
                      companyId={obj._id}
                      adminEmails={obj.adminEmails}
                      checked={checked}
                      zoneId={zoneId}
                    />
                  )
              ))
            )
          }
        </tbody>
      </table>
    </TableSection>
  );
}

CompaniesTable.propTypes = {
  companiesQuery: PropTypes.object.isRequired,
  categoriesQuery: PropTypes.object.isRequired,
  typesQuery: PropTypes.object.isRequired,
  zoneId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default compose(
  graphql(getCompaniesQuery, { name: 'companiesQuery' }),
  graphql(getCompanyCategoriesQuery, { name: 'categoriesQuery' }),
  graphql(getCompanyTypesQuery, { name: 'typesQuery' })
)(CompaniesTable);

import React, { useEffect, useRef, useState } from 'react'
import { REACT_APP_IMAGE_BASE_URL } from '../../env';
import { FiEdit } from 'react-icons/fi';
import { validateSvg } from '../../common';
import CouponsTable from './CouponsTable';
import { debounce } from 'lodash';
import { useZoneContext } from '../../context/ZoneContext';

function CompanyTableItem({ zoneId, company, categoriesQuery, setEditing, adminEmails, companyId, checked }) {
  const { companiesFilter } = useZoneContext();

  const contactDetails = [company?.contactPerson, company?.contactMobile, company?.adminEmails.join(', ')].filter(Boolean);
  const category = categoriesQuery?.categories?.find((category) => category._id === company.companyCategoryId);

  const [isCompanyHovered, setIsCompanyHovered] = useState(false);

  const [isInView, setIsInView] = useState(false);


  const companyRef = useRef(null);

  useEffect(() => {
    const debouncedHandleScroll = debounce(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, 500); // 500ms debounce (to make sure the user stopped scrolling)

    const observer = new IntersectionObserver(debouncedHandleScroll);

    if (companyRef.current) {
      observer.observe(companyRef.current);
    }

    return () => {
      if (companyRef.current) {
        observer.unobserve(companyRef.current);
      }
    };
  }, [company]);

  const handleMouseEnter = () => setIsCompanyHovered(true);
  const handleMouseLeave = () => setIsCompanyHovered(false);

  return (
    <>
      <tr
        ref={companyRef}
        className={`company company-row ${isCompanyHovered ? 'hovered' : ''}`}
        style={{ opacity: companiesFilter.companiesToHide.includes(company._id) ? '0.25' : '1' }}
      >
        <td
          className="company-info company-img"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {company.logoURL ? <img alt="Företagslogotyp" src={`${REACT_APP_IMAGE_BASE_URL}${company.logoURL}`} style={{ maxWidth: '100px', maxHeight: '60px' }} /> : ''}
        </td>
        <td
          className="company-info company-name"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <strong>{company.name}</strong>
          {contactDetails.length > 0 && <div>Ansvarig kontakt: {contactDetails.join(', ')}</div>}
        </td>
        <td
          className="company-info company-category"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div>
            <div className='company-category-icon' dangerouslySetInnerHTML={{ __html: validateSvg(category?.iconPath) ? category.iconPath : '' }} />
            {category?.name}
          </div>
        </td>
        <td onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <span onClick={() => setEditing(company._id)}><FiEdit size={20} /></span>
        </td>
      </tr>
      <CouponsTable
        key={`coupons-for-${company._id}`}
        companyId={companyId}
        adminEmails={adminEmails}
        checked={checked}
        zoneId={zoneId}
        isCompanyInView={isInView}
      />
    </>

  )
}



export default CompanyTableItem

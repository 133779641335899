import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getBookletsQuery } from '../../queries/queries';
import { LoadingTable, EditButton, sortList, SortButton, TableSection } from '../../common/index';
import Form from './BookletsForm';
import Sort from '../../img/sort.svg';
import { REACT_APP_IMAGE_BASE_URL } from '../../env'


function BookletsTable(props) {
  const { bookletsQuery } = props;
  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('title');
  const [sortOrder, setSortOrder] = useState(1);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };


  return (
    <TableSection>
      <div className="table-header">
        <h2>Häften</h2>
        <EditButton size="sm add" setEditing={() => setEditing('new')} >Lägg till</EditButton>
      </div>
      <table className="booklet-table">
        <thead>
          <tr>
            <td className="centered-logo">Logga</td>
            <td><SortButton name="title" sort={sort}>Häfte<img className="sortBtn" src={Sort} alt="" /></SortButton></td>
            <td><SortButton name="buyableFrom" sort={sort}>Säljperiod<img className="sortBtn" src={Sort} alt="" /></SortButton></td>
            <td><SortButton name="validFrom" sort={sort}>Giltig<img className="sortBtn" src={Sort} alt="" /></SortButton></td>
            <td>Färger</td>
            <td>Pris (kr)</td>
            <td className="booklet-sold">Aktiverade/Sålda</td>
            <td></td>
          </tr>
        </thead>
        <tbody>

          {editing === 'new'
            && <Form key="new" onComplete={() => setEditing('')} />}

          {!bookletsQuery.booklets ? <LoadingTable query={bookletsQuery} /> : (
            sortList(bookletsQuery.booklets, sort).map((obj) => (
              editing === obj._id
                ? <Form key={obj._id} content={obj} onComplete={() => setEditing('')} />
                : (
                  <tr key={obj._id}>
                    <td className="centered-logo">{obj.logoURL ? <img alt="logo" src={`${REACT_APP_IMAGE_BASE_URL}${obj.logoURL}`} style={{ maxWidth: '100px', maxHeight: '60px' }} /> : ''}</td>
                    <td>{obj.title}</td>
                    <td>{new Date(`${obj.buyableFrom}`).toLocaleDateString()} - {new Date(`${obj.buyableTo}`).toLocaleDateString()}</td>
                    <td>{new Date(`${obj.validFrom}`).toLocaleDateString()} - {new Date(`${obj.validTo}`).toLocaleDateString()}</td>
                    <td>
                      <div title="Färg vänster" style={{ backgroundColor: obj.colorLeft, width: '20px', height: '20px', display: 'inline-block' }}></div>
                      <div title="Färg höger" style={{ backgroundColor: obj.colorRight, width: '20px', height: '20px', display: 'inline-block' }}></div>
                      <div title="Färg text" style={{ backgroundColor: obj.colorFont, width: '20px', height: '20px', display: 'inline-block' }}></div>
                    </td>
                    <td>{obj.price}</td>
                    <td className="booklet-sold">{obj.usedOrders}/{obj.soldBooklets}</td>
                    <td>
                      <EditButton size="xs" setEditing={() => setEditing(obj._id)}>Redigera</EditButton>
                      <Link className="button secondary xs arrow" to={`/cities/${obj._id}`}>Preview</Link>
                    </td>
                  </tr>
                )
            ))
          )}

        </tbody>
      </table>
    </TableSection>
  );
}

BookletsTable.propTypes = {
  bookletsQuery: PropTypes.object.isRequired
};

export default compose(
  graphql(getBookletsQuery, { name: 'bookletsQuery' })
)(BookletsTable);



import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { useZoneContext } from '../../context/ZoneContext';
import { ImEyeBlocked, ImEye } from 'react-icons/im';


const CompaniesTableBooklet = ({ notAdminable, booklet }) => {
  const { adminableBooklets, notAdminableBooklets, companiesFilter, setCompaniesFilter, bookletsToHide, setBookletsToHide } = useZoneContext();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      setIsScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Utility function to check if today's date is within the date range
  const isDateActive = (startDate, endDate) => {
    const today = new Date();
    return today >= new Date(startDate) && today <= new Date(endDate);
  };

  // Function to get the class name based on the date's active status
  const getDateClassName = (isActive) => isActive ? 'is-active' : '';

  const handleSelectedBooklet = () => {
    if (companiesFilter.selectedBookletId === booklet._id) {
      setCompaniesFilter({ ...companiesFilter, selectedBookletId: '' })
    } else {
      setCompaniesFilter({ ...companiesFilter, selectedBookletId: booklet._id })
    }
  }

  const handleHideBooklet = (event) => {
    event.stopPropagation();
    console.log('Hide booklet', booklet._id);
    setBookletsToHide([...bookletsToHide, booklet._id]);
  }

  const handleSoloBooklet = (event) => {
    event.stopPropagation();
    console.log('Solo booklet', booklet._id);
    const allBooklets = [...adminableBooklets, ...notAdminableBooklets];

    // Set every booklet to hidden except the selected one
    const hiddenBooklets = allBooklets.map(booklet => booklet._id);
    const index = hiddenBooklets.indexOf(booklet._id);
    hiddenBooklets.splice(index, 1);

    setBookletsToHide(hiddenBooklets);
  }

  return (
    <div className={`booklet-column-container ${isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div
        className={`booklet-heading-container ${companiesFilter.selectedBookletId === booklet._id ? 'active' : ''}`}
        onClick={handleSelectedBooklet}
        initial={false}
        animate={isScrolled ? { top: 0 } : { top: 176 }}
        transition={{
          duration: 0.4,
          ease: 'easeOut'
        }}
      >
        <div className="flex">
          <div>
            <span className='booklet-heading'>{booklet.title} {!notAdminable && `(${booklet.price}:-)`}</span>
            <div className='booklet-dates'>
              <div>
                {notAdminable
                  ? <div />
                  : <span className={getDateClassName(isDateActive(booklet.buyableFrom, booklet.buyableTo))}>
                    <b>Säljs</b> - {new Date(booklet?.buyableFrom).toLocaleDateString()} - {new Date(booklet?.buyableTo).toLocaleDateString()}
                  </span>
                }

              </div>
              <div>
                {notAdminable
                  ? <div />
                  : <span className={getDateClassName(isDateActive(booklet.validFrom, booklet.validTo))}>
                    <b>Giltigt</b> - {new Date(booklet?.validFrom).toLocaleDateString()} - {new Date(booklet?.validTo).toLocaleDateString()}
                  </span>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="booklet-filter-buttons">
          <button className='btn-hide-booklet' onClick={handleHideBooklet}>
            <ImEyeBlocked size={14} />
          </button>
          <button className='btn-solo-booklet' onClick={handleSoloBooklet}>
            <span className='solo-badge'>1</span>
            <ImEye size={14} />
          </button>
        </div>
      </motion.div>

    </div>
  );
}

export default CompaniesTableBooklet;

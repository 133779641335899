import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getZonesQuery } from '../../queries/queries';
import { LoadingTable, EditButton, sortList, SortButton, TableSection, Values } from '../../common/index';
import Form from './ZonesForm';
import Sort from '../../img/sort.svg';


function ZonesTable(props) {
  const { zonesQuery } = props;
  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState(1);
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };

  return (
    <TableSection>
      <div className="table-header">
        <h2>Zoner</h2>
        <EditButton size="sm add" setEditing={() => setEditing('new')} >Lägg till</EditButton>
      </div>
      <table className="zone-table">
        <thead>
          <tr>
            <td><SortButton name="name" sort={sort}>Zon<img className="sortBtn" alt="" src={Sort} /></SortButton></td>
            <td>Administratörer</td>
            <td></td>
          </tr>
        </thead>
        <tbody>

          {editing === 'new'
            && <Form key="new" onComplete={() => setEditing('')} />}

          {!zonesQuery.zones ? <LoadingTable query={zonesQuery} /> : (
            sortList(zonesQuery.zones, sort).map((obj) => (
              editing === obj._id
                ? <Form key={obj._id} content={obj} onComplete={() => setEditing('')} />
                : (
                  <tr key={obj._id}>
                    <td>{obj.name}</td>
                    <td><Values values={obj.adminEmails} /></td>
                    <td>
                      <EditButton size="xs" setEditing={() => setEditing(obj._id)}>Redigera</EditButton>
                      <Link className="button secondary xs arrow" to={`/cities/${obj._id}/companies`}>Företag</Link>
                      <Link className="button secondary xs arrow" to={`/cities/${obj._id}/associations`}>Föreningar</Link>
                    </td>
                  </tr>
                )
            ))
          )}

        </tbody>
      </table>
    </TableSection>
  );
}

ZonesTable.propTypes = {
  zonesQuery: PropTypes.object.isRequired
};

export default compose(
  graphql(getZonesQuery, { name: 'zonesQuery' })
)(ZonesTable);



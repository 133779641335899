import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getCouponsQuery, addCouponMutation } from '../../queries/queries';
import { LoadingTable, sortList } from '../../common/index';
import CouponsForm from './CouponsForm';
import { FiEdit } from 'react-icons/fi';
import { IoDuplicateOutline } from 'react-icons/io5';
import ReviewPopUp from './ReviewPopUp';
import { useZoneContext } from '../../context/ZoneContext';
import { useMutation, useLazyQuery } from 'react-apollo';

function CouponsTable({ zoneId, adminEmails, companyId, checked, isCompanyInView }) {
  const { adminableBooklets, notAdminableBooklets, companiesFilter, setCompaniesFilter } = useZoneContext();

  const [addCoupon, { loading: addCouponLoading }] = useMutation(addCouponMutation);
  const [fetchCoupons, { data, loading, error }] = useLazyQuery(getCouponsQuery, {
    variables: { companyId },
    onCompleted: () => setIsDataFetched(true),
  });

  const [editing, setEditing] = useState('');
  const [sortBy, setSortBy] = useState('position');
  const [sortOrder, setSortOrder] = useState(1);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const coupons = data?.coupons || [];
  const sort = { sortBy, sortOrder, setSortBy, setSortOrder };

  // Fetch coupons when company is in view
  useEffect(() => {
    if (isCompanyInView && !isDataFetched) {
      fetchCoupons();
    }
  }, [isCompanyInView, isDataFetched, fetchCoupons]);

  // Function to determine if a company should be hidden
  const shouldHideCompany = (companyId, selectedBooklet, checkedCoupons, coupons) => {
    if (!selectedBooklet || !checkedCoupons || !coupons) return false;

    const relevantCheckedCoupons = checkedCoupons.filter(checkedItem => selectedBooklet.couponIds.includes(checkedItem.split('-')[1]));
    const couponIdsInChecked = relevantCheckedCoupons.map(checkedItem => checkedItem.split('-')[1]);
    return !coupons.some(coupon => coupon.companyId === companyId && couponIdsInChecked.includes(coupon._id));
  };

  // Get the relevant checked coupons for the selected booklet
  const relevantCheckedCoupons = useMemo(() => {
    return checked.filter(checkedItem => {
      const booklet = adminableBooklets?.find(b => b._id === companiesFilter.selectedBookletId) ||
        notAdminableBooklets?.find(b => b._id === companiesFilter.selectedBookletId);
      return booklet && booklet.couponIds.includes(checkedItem.split('-')[1]);
    });
  }, [checked, companiesFilter, adminableBooklets, notAdminableBooklets]);

  // useEffect hook to update companiesToHide
  useEffect(() => {
    if (!coupons.length || !companyId || !companiesFilter) return;

    const selectedBooklet = adminableBooklets?.find(b => b._id === companiesFilter.selectedBookletId) ||
      notAdminableBooklets?.find(b => b._id === companiesFilter.selectedBookletId);

    const hideCompany = shouldHideCompany(companyId, selectedBooklet, relevantCheckedCoupons, coupons);

    setCompaniesFilter(prevState => {
      const companiesToHideSet = new Set(prevState.companiesToHide);
      const needsUpdate = hideCompany ? companiesToHideSet.add(companyId).size !== prevState.companiesToHide.length
        : companiesToHideSet.delete(companyId);

      return needsUpdate ? { ...prevState, companiesToHide: Array.from(companiesToHideSet) } : prevState;
    });
  }, [companiesFilter, relevantCheckedCoupons, coupons, companyId, setCompaniesFilter]);

  const handleDuplicate = (e, coupon) => {
    e.stopPropagation();
    if (addCouponLoading) return;

    addCoupon({
      variables: {
        companyId,
        imageURL: coupon.imageURL,
        discount: coupon.discount,
        smallTitle: coupon.smallTitle,
        productLink: coupon.productLink,
        terms: coupon.terms,
        description: coupon.description,
        code: coupon.code,
      },
      refetchQueries: [{ query: getCouponsQuery, variables: { companyId } }]
    }).then((res) => {
      console.log('duplicated', res);
    }).catch((err) => {
      console.log('error', err);
    });
  }

  return (
    <>
      {editing === 'new' && <CouponsForm key="new" content={{ companyId }} onComplete={() => setEditing('')} />}
      <tr id={`locator-${companyId}`}></tr>
      {
        // Table row for coupon
        !data?.coupons ? <LoadingTable loading={loading} error={error} /> : (
          sortList(coupons, sort).map((couponObj, index) => (
            editing === couponObj._id
              ? <CouponsForm key={couponObj._id} content={couponObj} onComplete={() => setEditing('')} />
              : (
                <tr
                  key={couponObj._id}
                  className='coupon'
                  style={{ opacity: companiesFilter.companiesToHide.includes(companyId) ? '0.25' : '1' }}
                >
                  <td></td>
                  <td colSpan={3} className={`coupon-title`} onClick={() => setEditing(couponObj._id)}>
                    <span>
                      {couponObj.discount + ' ' + couponObj.smallTitle}
                      <span>
                        <button onClick={(e) => handleDuplicate(e, couponObj)}><IoDuplicateOutline size={20} /></button>
                        <button onClick={() => setEditing(couponObj._id)}><FiEdit size={20} /></button>
                      </span>
                    </span>
                  </td>
                  {index === 0 && (
                    adminableBooklets?.map((bookletItem) => (
                      <ReviewPopUp
                        key={bookletItem._id}
                        coupons={sortList(coupons, sort)}
                        booklet={bookletItem}
                        adminEmails={adminEmails}
                        zoneId={zoneId}
                        isCompanyInView={isCompanyInView}
                      />
                    ))
                  )}
                  {index === 0 && (
                    notAdminableBooklets?.map((bookletItem) => (
                      <ReviewPopUp
                        key={bookletItem._id}
                        coupons={sortList(coupons, sort)}
                        booklet={bookletItem}
                        adminEmails={adminEmails}
                        zoneId={zoneId}
                        isCompanyInView={isCompanyInView}
                      />
                    ))
                  )}
                </tr>
              )
          ))
        )
      }
      <tr className='add-coupons' style={{ opacity: companiesFilter.companiesToHide.includes(companyId) ? '0.25' : '1' }}>
        <td></td>
        <td>
          <button className='add-coupon' onClick={() => setEditing('new')}>Lägg till kupong</button>
        </td>
        <td colSpan={2}></td>
      </tr>
    </>
  );
}

CouponsTable.propTypes = {
  zoneId: PropTypes.string.isRequired,
  adminEmails: PropTypes.array.isRequired,
  companyId: PropTypes.string.isRequired,
  checked: PropTypes.array.isRequired,
  isCompanyInView: PropTypes.bool.isRequired,
};

export default CouponsTable;

import gql from 'graphql-tag';

// QUERIES


const getCitiesQuery = gql`
  {
    cities: cities {
      _id
      name
      adminEmails
      activeFrom
    }
  }
`;

const getActiveCitiesQuery = gql`
  {
    cities: activeCities {
      _id
      name
      price
      tax
      cityPrice
      cityTax
      logoURL
      bookletColorLeft
      bookletColorRight
      slug
      onlyVisibleOnDomains
    }
  }
`;

/*const getBookletQuery = gql`
  query($cityId: ObjectId!) {
    booklet(cityId: $cityId) {
      title
      subTitle
      startDate
      endDate
      city {
        name
        logoURL
      }
      calculatedCompanies {
        _id
        name
        logoURL
        websiteURL
        typeId
        visitAddress {
          streetAddress
          city
        }
        calculatedCoupons {
          _id
          discount 
          description
          smallTitle
          productLink
        }
      }
    }
  }
`
*/

const getCityBySlug = gql`
  query ($slug: String!) {
    city: cityBySlug(slug: $slug) {
      _id
      name
      price
      tax
      cityPrice
      cityTax
    }
  }
`

/*
const statAllOrdersQuery = gql`
  query ($fromPeriod: Int, $toPeriod: Int) {
    allOrders: statAllOrders(fromPeriod: $fromPeriod, toPeriod: $toPeriod) {
      periods {
        period
        amount
        number
      }
      totalNumber
      totalAmount
    }
  }
`;
*/


const statOrdersByCityQuery = gql`
  query ($cityId: ObjectId!, $fromPeriod: Int, $toPeriod: Int) {
    orders: statOrdersByCity(cityId: $cityId, fromPeriod: $fromPeriod, toPeriod: $toPeriod) {
      periods {
        period
        amount
        number
      }
      totalNumber
      totalAmount
    }
  }
`;

const statCouponsByCityQuery = gql`
  query ($cityId: ObjectId!, $fromPeriod: Int, $toPeriod: Int) {
    coupons: statCouponsByCity(cityId: $cityId, fromPeriod: $fromPeriod, toPeriod: $toPeriod) {
      used {
        agreement
        season1
        season2
        season3
      }
      all {
        agreement {
          active
          total
        }
        season1 {
          active
          total
        }
        season2 {
          active
          total
        }
        season3 {
          active
          total
        }
      }
      topAgreement {
        coupon {
          _id
          smallTitle
          usedCache {
            userId
          }
        }
        company {
          name
        }
      }
      topSeason {
        coupon {
          _id
          smallTitle
          usedCache {
            userId
          }
        }
        company {
          name
        }
      }
    }
  }
`;

// MUTATIONS

const addCityMutation = gql`
  mutation(
    $name: String!
    $adminEmails: [String!]
    $activeFrom: Int!
    ) {
    createCity(
      name: $name
      adminEmails: $adminEmails,
      activeFrom: $activeFrom,
      ) {
        name
    }
  }
`;

const updateCityMutation = gql`
  mutation(
    $_id: ObjectId!
    $name: String!
    $adminEmails: [String!]
    $activeFrom: Int!
    ) {
    updateCity(
      _id: $_id,
      name: $name
      adminEmails: $adminEmails,
      activeFrom: $activeFrom,
      ) {
        name
    }
  }
`;

export {
  getActiveCitiesQuery,
  getCitiesQuery,
  getCityBySlug,
  //getBookletQuery,
  statOrdersByCityQuery,
  //statAllOrdersQuery,
  statCouponsByCityQuery,
  addCityMutation,
  updateCityMutation,
};

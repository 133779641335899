import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { getCouponPositionsQuery, getCouponsQuery, addCouponMutation, updateCouponMutation } from '../../queries/queries';
import { Form, Field, Group } from '../../common/index';

function CouponsForm(props) {
  const { content, onComplete, positionsQuery } = props;
  const [sContent, setSContent] = useState({
    ...content,
    terms: content && content.terms ? content.terms : 'Kan ej kombineras med andra erbjudanden.',
  });

  const ss = (c) => setSContent({ ...sContent, ...c });

  return (
    <tr key={content ? content._id : 'new'} className="open">
      <td colSpan={4}>
        <Form sContent={sContent} parent="companyId" get={getCouponsQuery} add={addCouponMutation} update={updateCouponMutation} onComplete={onComplete}>
          <Group title="Texter">
            <Field type="text" req name="discount" title="Rabatt *" value={sContent && sContent.discount} onChange={ss} />
            <Field type="text" req name="smallTitle" title="Liten titel *" value={sContent && sContent.smallTitle} onChange={ss} />
            <Field type="text" req x2 name="terms" title="Villkor titel" value={sContent && sContent.terms} onChange={ss} />
            <Field type="text" req x2 name="description" title="Beskrivning" value={sContent && sContent.description} onChange={ss} />
          </Group>
          <Group title="Inställningar">
            <Field type="select" name="position" title="Position" value={sContent && sContent.position} selectDataInfo={positionsQuery} selectData={positionsQuery.positions} onChange={ss} />
            <Field type="image" name="imageURL" title="Bild (max 1MB)" value={sContent && sContent.imageURL} onChange={ss} />
            <Field type="text" name="code" title="Rabattkod" value={sContent && sContent.code} onChange={ss} />
            <Field type="text" name="productLink" title="Produkt-URL" value={sContent && sContent.productLink} onChange={ss} />
          </Group>

        </Form>
      </td>
    </tr>
  );
}

CouponsForm.propTypes = {
  content: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  positionsQuery: PropTypes.object.isRequired
};

CouponsForm.defaultProps = {
  content: null,
};



export default compose(
  graphql(getCouponPositionsQuery, { name: 'positionsQuery' }),
)(CouponsForm);

import React from 'react';
import { PageHeader } from '../../common/index';
import Statistics from './CitiesStatistics';
import BookletsTable from './BookletsTable';
import ZonesTable from './ZonesTable';
import { useNavigationContext } from '../../context/NavigationContext';

function Cities() {
  const { setNavigationActivated } = useNavigationContext();
  React.useEffect(() => {
    setNavigationActivated(true);
  }, []);

  return (
    <div id="cities">
      <PageHeader>
        <h1>Administration</h1>
        <p><strong>Du är inloggad som superadmin.</strong></p>
      </PageHeader>
      <Statistics />
      <ZonesTable />      
      <BookletsTable />
    </div>
  );
}

export default Cities;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { addBookletMutation, getBookletsQuery, updateBookletMutation } from '../../queries/queries';
import { Form, Field, Group } from '../../common/index';

function BookletsForm(props) {
  const { content, onComplete } = props;
  const [sContent, setSContent] = useState(content);

  const ss = (b) => setSContent({ ...sContent, ...b });

  return (
    <tr key={content ? content.id : 'new'} className="open">
      <td colSpan="100">
        <Form sContent={sContent} get={getBookletsQuery} add={addBookletMutation} update={updateBookletMutation} onComplete={onComplete}>
          <Group title="Allmänt">
            <Field type="text" req name="title" title="Titel på häfte *" value={sContent && sContent.title} onChange={ss} />
            <Field type="number" req name="price" title="Pris" value={sContent && sContent.price} onChange={ss} />
            <Field type="admins" x2 name="adminEmails" title="Administratörer" value={sContent && sContent.adminEmails} onChange={ss} />
          </Group>
          <Group title="Säljperiod">
            <Field req type="date" name="buyableFrom" title="Startdatum *" value={sContent && new Date(sContent.buyableFrom).toLocaleDateString()} onChange={ss} />
            <Field req type="date" name="buyableTo" title="Slutdatum *" value={sContent && new Date(sContent.buyableTo).toLocaleDateString()} onChange={ss} />
          </Group>
          <Group title="Giltighet">
            <Field req type="date" name="validFrom" title="Startdatum *" value={sContent && new Date(sContent.validFrom).toLocaleDateString()} onChange={ss} />
            <Field req type="date" name="validTo" title="Slutdatum *" value={sContent && new Date(sContent.validTo).toLocaleDateString()} onChange={ss} />
          </Group>
          <Group title="Utseende">
            <Field type="text" name="colorLeft" title="Färg på vänster sida" value={sContent && sContent.colorLeft} onChange={ss} />
            <Field type="text" name="colorRight" title="Färg på höger sida" value={sContent && sContent.colorRight} onChange={ss} />
            <Field type="text" name="colorFont" title="Färg på text" value={sContent && sContent.colorFont} onChange={ss} />
            <Field type="image" name="logoURL" title="Logotyp (max 1MB)" value={sContent && sContent.logoURL} onChange={ss} />
            <Field type="image" name="imageOfBookletInAppURL" title="Bild på häfte i appen (max 1MB)" value={sContent && sContent.imageOfBookletInAppURL} onChange={ss} />
          </Group>
        </Form>
      </td>
    </tr>
  )
}

BookletsForm.propTypes = {
  content: PropTypes.object,
  onComplete: PropTypes.func.isRequired,
}

BookletsForm.defaultProps = {
  content: null,
}

export default BookletsForm;


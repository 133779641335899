import React from 'react'
import { IoChevronDownCircleSharp, IoChevronUpCircleSharp } from 'react-icons/io5';
import { usePappersHafteContext } from '../../context/PappersHafteContext';
import Category from './Category';

const CategoryGroup = ({ category, categoryGroup, pageIndex, categoryIndex }) => {
  const { booklet, setTableOfContent } = usePappersHafteContext();

  const categorySetting = booklet?.categorySettings?.find(setting => setting.categoryId === category._id);
  const categoryColor = categorySetting?.color || booklet?.colorLeft;

  const moveDown = (pageIndex, categoryIndex) => {
    setTableOfContent((prevPages) => {
      const newPages = [...prevPages];
      const categoryToMove = newPages[pageIndex].splice(categoryIndex, 1)[0];

      if (pageIndex + 1 < newPages.length) {
        newPages[pageIndex + 1].unshift(categoryToMove);
      } else {
        newPages.push([categoryToMove]);
      }

      return newPages;
    });
  };

  const moveUp = (pageIndex, categoryIndex) => {
    setTableOfContent((prevPages) => {
      const newPages = [...prevPages];
      const categoryToMove = newPages[pageIndex].splice(categoryIndex, 1)[0];

      if (pageIndex - 1 >= 0) {
        newPages[pageIndex - 1].push(categoryToMove);
      } else {
        newPages[pageIndex].unshift(categoryToMove);
      }

      // If the page is empty after moving the category, remove it
      if (newPages[pageIndex].length === 0) {
        newPages.splice(pageIndex, 1);
      }

      return newPages;
    });
  };

  return (
    <div className='ph-category-container relative'>
      <div className="ph-category" style={{ background: categoryColor, color: booklet?.colorFont }}>
        <h3>{category.name}</h3>
        <ul>
          {category.companies.map((company, companyIndex) =>
            <Category key={companyIndex} company={company} />
          )}
        </ul>
      </div>
      <div className="move-buttons flex items-center">
        {pageIndex !== 0 && (
          <button onClick={() => moveUp(pageIndex, categoryIndex)}>
            <IoChevronUpCircleSharp size='1.5vw' />
          </button>
        )}
        {categoryGroup.length > 1 && (
          <button onClick={() => moveDown(pageIndex, categoryIndex)}>
            <IoChevronDownCircleSharp size='1.5vw' />
          </button>
        )}
      </div>
    </div>
  )
}

export default CategoryGroup

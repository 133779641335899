import gql from 'graphql-tag';

// QUERIES

const getZonesQuery = gql`
  {
    zones: zones {
      _id
      name
      adminEmails
    }
  }
`;

const getZonesPublicQuery = gql`
  {
    zones: zonesPublic {
      _id
      onlyVisibleOnDomains
    }
  }
`;

const getZoneQuery = gql`
  query ($_id: ObjectId!) {
    zone: zone(_id: $_id) {
      name
    }
  }
`;


const statAllOrdersQuery = gql`
  query ($fromDate: DateTime, $toDate: DateTime) {
    allOrders: statAllOrders(fromDate: $fromDate, toDate: $toDate) {
      #periods {
        #period
        #amount
        #number
      #}
      totalNumber
      #totalAmount
    }
  }
`;


// MUTATIONS

const addZoneMutation = gql`
  mutation(
    $name: String!
    $adminEmails: [String!]
    ) {
    createZone(
      name: $name
      adminEmails: $adminEmails,
      ) {
        name
    }
  }
`;

const updateZoneMutation = gql`
  mutation(
    $_id: ObjectId!
    $name: String!
    $adminEmails: [String!]
    ) {
    updateZone(
      _id: $_id,
      name: $name
      adminEmails: $adminEmails,
      ) {
        name
    }
  }
`;

export {
  getZonesQuery,
  getZonesPublicQuery,
  getZoneQuery,
  statAllOrdersQuery,
  addZoneMutation,
  updateZoneMutation,
};

import React, { useEffect, useState } from 'react';

const ZoneContext = React.createContext();

export const ZoneProvider = (props) => {

  const [zone, setZone] = useState(null);
  const [adminableBookletsQuery, setAdminableBookletsQuery] = useState([]);
  const [notAdminableBookletsQuery, setNotAdminableBookletsQuery] = useState([]);
  const [adminableBooklets, setAdminableBooklets] = useState([]);
  const [notAdminableBooklets, setNotAdminableBooklets] = useState([]);

  // Filter data
  const [bookletsToHide, setBookletsToHide] = useState([]);
  const [companiesFilter, setCompaniesFilter] = useState({
    selectedBookletId: '',
    companiesToHide: []
  });

  // Initial set of booklets
  useEffect(() => {
    setAdminableBooklets(adminableBookletsQuery);
    setNotAdminableBooklets(notAdminableBookletsQuery);
  }, [adminableBookletsQuery, notAdminableBookletsQuery]);


  // Handle filtering of booklets
  useEffect(() => {
    if (bookletsToHide.length > 0) {
      const updatedAdminableBooklets = adminableBooklets.filter(booklet => !bookletsToHide.includes(booklet._id));
      const updatedNotAdminableBooklets = notAdminableBooklets.filter(booklet => !bookletsToHide.includes(booklet._id));

      setAdminableBooklets(updatedAdminableBooklets);
      setNotAdminableBooklets(updatedNotAdminableBooklets);
    }
    else {
      setAdminableBooklets(adminableBookletsQuery);
      setNotAdminableBooklets(notAdminableBookletsQuery);
    }
  }, [bookletsToHide]);

  const context = {
    zone,
    adminableBooklets,
    notAdminableBooklets,
    companiesFilter,
    bookletsToHide,
    setZone,
    setAdminableBookletsQuery,
    setNotAdminableBookletsQuery,
    setCompaniesFilter,
    setBookletsToHide,
  };

  return (
    <ZoneContext.Provider value={context}>
      {props.children}
    </ZoneContext.Provider>
  );
};

export const useZoneContext = () => React.useContext(ZoneContext);

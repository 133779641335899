import gql from 'graphql-tag';

// QUERIES

const getBookletsQuery = gql`
  {
    booklets: booklets {
      _id
      title
      adminEmails
      buyableFrom
      buyableTo
      validFrom
      validTo
      price
      soldBooklets
      usedOrders
      logoURL
      colorLeft
      colorRight
      colorFont
      couponIds
      imageOfBookletInAppURL
      shopURL
    }
  }
`;

const getAdminableBookletsQuery = gql`
  query ($zoneId: ObjectId!) {
    booklets: myAdminableBooklets(zoneId: $zoneId) {
      _id
      title
      couponIds
      buyableFrom
      buyableTo
      validFrom
      validTo
      price
    }
  }
`;

const getNotAdminableBookletsQuery = gql`
  query ($zoneId: ObjectId!) {
    booklets: myNotAdminableBooklets(zoneId: $zoneId) {
      _id
      title
      couponIds
    }
  }
`;

const getBuyableBookletsQuery = gql`
  {
    booklets: buyableBooklets{
      _id
      title
      validFrom
      validTo
      price
      logoURL
      colorLeft
      colorRight
      colorFont
      onlyVisibleOnDomains
      zoneId
      purchasableThroughAssociations
    }
  }
`;

const getBookletQuery = gql`
  query($_id: ObjectId!) {
    booklet: booklet(_id: $_id) {
      _id
      title
      validFrom
      validTo
      colorLeft
      colorRight
      colorFont
      logoURL
      userOwnsBooklet
      price
      imageOfBookletInAppURL
      shopURL
      categorySettings {
        categoryId
        color
        order
      }
      calculatedCompanies {
        _id
        name
        logoURL
        websiteURL
        typeId
        companyCategoryId
        phone
        visitAddress {
          streetAddress
          city
        }
        calculatedCoupons {
          _id
          discount 
          description
          smallTitle
          productLink
          availableByUser
          terms
          imageURL
          companyId
          code
        }
      }
    }
  }
`;

const categoriesInBookletQuery = gql`query CategoriesInBooklet($bookletId: ObjectId!) {
  categoriesInBooklet(bookletId: $bookletId) {
    name,
    _id,
    iconPath
  }
}`;

const companyFromBookletQuery = gql`query CompanyFromBooklet($bookletId: ObjectId!, $companyId: ObjectId!) {
  companyFromBooklet(bookletId: $bookletId, companyId: $companyId
  ) {
    _id
    name
    logoURL
    websiteURL
    typeId
    companyCategoryId
    phone
    visitAddress {
      streetAddress
      city
    }
    calculatedCoupons {
      _id
      discount 
      description
      smallTitle
      productLink
      availableByUser
      terms
      imageURL
    }
  }
}`;



// MUTATIONS

const addBookletMutation = gql`
  mutation(
    $title: String!
    $adminEmails: [String!]
    $buyableFrom: DateTime!
    $buyableTo: DateTime!
    $validFrom: DateTime!
    $validTo: DateTime!
    $price: Float
    $logoURL: String
    $colorLeft: String
    $colorRight: String
    $colorFont: String
    $imageOfBookletInAppURL: String 
    ) {
    createBooklet(
      title: $title
      adminEmails: $adminEmails,
      buyableFrom: $buyableFrom,
      buyableTo: $buyableTo,
      validFrom: $validFrom,
      validTo: $validTo,
      price: $price,
      logoURL: $logoURL,
      colorLeft: $colorLeft,
      colorRight: $colorRight,
      colorFont: $colorFont
      imageOfBookletInAppURL: $imageOfBookletInAppURL
      ) {
        title
    }
  }
`;

const updateBookletMutation = gql`
  mutation(
    $_id: ObjectId!
    $title: String!
    $adminEmails: [String!]
    $buyableFrom: DateTime!
    $buyableTo: DateTime!
    $validFrom: DateTime!
    $validTo: DateTime!
    $price: Float
    $logoURL: String
    $colorLeft: String
    $colorRight: String
    $colorFont: String
    $couponIds: [String!]
    $imageOfBookletInAppURL: String
    ) {
    updateBooklet(
      _id: $_id,
      title: $title
      adminEmails: $adminEmails,
      buyableFrom: $buyableFrom,
      buyableTo: $buyableTo,
      validFrom: $validFrom,
      validTo: $validTo,
      price: $price,
      logoURL: $logoURL,
      colorLeft: $colorLeft,
      colorRight: $colorRight,
      colorFont: $colorFont,
      couponIds: $couponIds
      imageOfBookletInAppURL: $imageOfBookletInAppURL
      ) {
        title
    }
  }
`;

const updateCouponsInBookletMutation = gql`
  mutation(
    $_id: ObjectId!
    $title: String!
    $couponIds: [String!]
    ) {
    updateBooklet(
      _id: $_id,
      title: $title
      couponIds: $couponIds
      ) {
        title
    }
  }
`;

export {
  getBookletsQuery,
  getBuyableBookletsQuery,
  getBookletQuery,
  getAdminableBookletsQuery,
  getNotAdminableBookletsQuery,
  categoriesInBookletQuery,
  companyFromBookletQuery,
  addBookletMutation,
  updateBookletMutation,
  updateCouponsInBookletMutation
};

import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery, useMutation } from 'react-apollo';
import { getBookletQuery } from '../../queries/booklet';
import { REACT_APP_BRAND } from '../../env';
import LogoWhite from '../../img/sa-enkelt-logo-white.svg';
import CityLogo from '../../img/city-logo.svg';
import { useNavigationContext } from '../../context/NavigationContext';
import { getCompanyTypesQuery } from '../../queries/company';
import CompanyReviewCoupons from './CompanyReviewCoupons';
import CompanyReviewBooklet from './CompanyReviewBooklet';
import { companyReviewByTokenQuery, updateBookletCompanyReviewWithTokenMutation } from '../../queries/bookletCompanyReview';

function CompanyReview(props) {
  const { token } = props.match.params;
  const { setNavigationActivated } = useNavigationContext();
  const { data: companyTypesData } = useQuery(getCompanyTypesQuery);
  const { data: companyReviewData, loading: companyReviewLoading, error: companyReviewError, refetch: refetchReview } = useQuery(companyReviewByTokenQuery, { variables: { token: token } });
  const [getBooklet, { data: bookletData, loading: bookletLoading, error: bookletError }] = useLazyQuery(getBookletQuery);
  const [ approveReview ] = useMutation(updateBookletCompanyReviewWithTokenMutation);

  const [ isCommentOpen, setIsCommentOpen ] = useState(false);
  const [ message, setMessage ] = useState('');

  useEffect(() => {
    setNavigationActivated(false);
  }, []);

  useEffect(() => {
    if (companyReviewData?.bookletCompanyReviewByToken?.bookletId) {
      getBooklet({ variables: { _id: companyReviewData.bookletCompanyReviewByToken.bookletId } });
    }
  }, [companyReviewData, getBooklet]);

  if (companyReviewLoading || bookletLoading) return null;
  if (companyReviewError || bookletError) {
    console.log('Error', companyReviewError, bookletError);
  }

  const handleClickApprove = () => {
    approveReview({
      variables: {
        token: token,
        isApproved: true
      }
    }).then(() => {
      refetchReview();
    }).catch(error => {
      console.error('Error approving review in handleClickApprove: ', error)
    })

  }
  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  }
  const handleSendComment = () => {
    approveReview({
      variables: {
        token: token,
        comment: message
      }
    }).then(() => {
      refetchReview();
      setIsCommentOpen(false);
    }).catch(error => {
      console.error('Error approving review in handleSendComment: ', error)
    })
  }

  return (
    <div id="company-review" className='flex flex-col items-center'>
      <header className={`flex flex-col justify-center items-center ${REACT_APP_BRAND === 'SAENKELT' ? 'saenkelt' : ''}`}>
        <img src={REACT_APP_BRAND === 'CITY' ? CityLogo : LogoWhite} alt={REACT_APP_BRAND === 'CITY' ? 'City Logo' : 'Så Enkelt Logo'} />
      </header>
      {!companyReviewError && !bookletError
        ? <>
          {!companyReviewData?.bookletCompanyReviewByToken?.isActive ?
            <div className='relative company-review-content flex flex-col items-center'>
              <h2>Innehållsgranskning</h2>
              <p>Denna granskningslänk är inte längre giltig. Vänligen kontakta <a href="https://saenkelt.se/kundtjanst/">kundtjänst</a> om ni ser ett behov av att granska eller justera era kuponger.</p>
            </div>
            :
            companyReviewData?.bookletCompanyReviewByToken?.approvedDate ?
              <div className='relative company-review-content flex flex-col items-center'>
                <h2>Innehållsgranskning</h2>
                <p>Tack för er feedback! Ni valde att godkänna kupongerna och därmed kommer ni snart att synas i häftet.</p>
              </div>
              : companyReviewData?.bookletCompanyReviewByToken?.comment ?
                <div className='relative company-review-content flex flex-col items-center'>
                  <h2>Innehållsgranskning</h2>
                  <p>Ni har granskat och lämnat kommentar på era kuponger!</p>
                </div> 
                :              
                <>
                  <div className='relative company-review-content flex flex-col items-center'>
                    <h2>Innehållsgranskning</h2>
                    <p className='text-center'>Vänligen kontrollera om ni vill göra någon justering av innehållet nedan.</p>
                    <div className="company-review-apps flex">
                      <CompanyReviewBooklet
                        bookletData={bookletData}
                        companyReviewData={companyReviewData}
                        companyTypesData={companyTypesData}
                      />
                      <CompanyReviewCoupons
                        companyReviewData={companyReviewData}
                        companyTypesData={companyTypesData}
                      />
                    </div>
                  </div>
                  <div className="company-review-confirm flex flex-col items-center justify-center">
                    <h5 className='text-center my'>{isCommentOpen ? 'Ge din kommentar nedan': 'Vill ni justera eller godkänna innehållet?'}</h5>                  
                    { isCommentOpen ? 
                      
                      <textarea className="comment-box"name="" id="" onChange={handleMessageChange}></textarea>
                      
                      : null
                    }
                    <div className="flex gap-1 my">
                      { isCommentOpen ? <button className="btn btn-secondary secondary" onClick={() => setIsCommentOpen(false)}>Avbryt</button> : null}
                      { isCommentOpen ? <button className="btn btn-secondary primary" onClick={() => handleSendComment()}>Skicka kommentar</button> : null}
                      { !isCommentOpen ? <button className="btn btn-secondary primary" onClick={() => setIsCommentOpen(true)}>Justera</button> : null}
                      { !isCommentOpen ? <button className="btn btn-secondary primary" onClick={() => handleClickApprove()}>Godkänn</button> : null}
                    </div>
                  </div>
                </>
          }
        </>
        : <div className='relative company-review-content flex flex-col items-center'>
          <h2>Något gick fel</h2>
          <p>Vi kunde inte hitta någon granskning med den angivna länken.</p>
        </div>
      }
      {/*       <Modal isOpen={isCommentOpen}>
        <div className='' onClick={() => setIsCommentOpen(false)}><GrClose/></div>      
        <textarea name="" id="" cols="30" rows="10" onChange={handleMessageChange}></textarea>
        <button className='primary' onClick={() => handleSendComment()}>Skicka kommentar</button>
      </Modal> */}
    </div>
  )
}

export default CompanyReview
